<template>
  <div class="card">
    <h2 class="card-title">Listado de Procedimientos</h2>
    <DataTable
        :show-filter="true"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        :endpoint="ProcedimientosServices.api.getProcedimientos"
        @rowClicked="verProcedimiento"
    />
  </div>
</template>
<script>
import TableList from "@/components/elements/TableList";
import { ProcedimientosServices } from "../services/ProcedimientosServices";
import DataTable from "@/components/elements/DataTable.vue";

export default {
  name: "ListarProcedimientosAdmin",
  computed: {
    ProcedimientosServices() {
      return ProcedimientosServices
    }
  },
  components: {DataTable, TableList },
  data() {
    return {
      headers: ProcedimientosServices.headers(),
      body: ProcedimientosServices.body(),
      actionButton: {
        text: "Nuevo procedimiento",
        action: () => {
          this.$router.push({ name: "CrearProcedimiento" });
        },
      },
    };
  },
  methods: {
    verProcedimiento(procedimiento) {
      this.$router.push({ name: "VerProcedimiento", params: { id: procedimiento.id } });
    },
  },
};
</script>

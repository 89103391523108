<template>
  <div v-if="totalPages > 1">
    <ul class="pagination">
      <li v-if="!isFirstPage" class="page-item">
        <button
            class="btn btn-primary btn-paginator"
            type="button"
            @click="goToPrev"
        >
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>

      <li v-for="i of pages" :key="i" class="page-item">
        <button
            class="btn btn-primary btn-paginator"
            type="button"
            :class="{ active: currentPage === i }"
            @click="goToPage(i)"
        >
          {{ i }}
        </button>
      </li>

      <li v-if="!isLastPage" class="page-item">
        <button
            class="btn btn-primary btn-paginator"
            type="button"
            @click="goToNext"
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BasePaginator",
  props: {
    totalResults: {type: Number, required: true},
    resultsPerPage: {type: Number, required: true},
    currentPage: {type: Number, required: true}
  },
  computed: {
    totalPages: function(){
      if (this.totalResults === 0 || this.resultsPerPage === 0) {
        return 0
      }
      return Math.ceil(this.totalResults / this.resultsPerPage)
    },
    isFirstPage: function(){
      return this.currentPage === 1
    },
    isLastPage: function(){
      return this.currentPage >= this.totalPages
    },
    pages: function(){
      const pagesToShow = []
      if (this.isFirstPage) {
        for (let i = 1; i <= this.totalPages; i++) {
          if (i >= this.currentPage && i <= this.currentPage + 2) {
            pagesToShow.push(i)
          }
        }
      } else if (this.isLastPage) {
        for (let i = 1; i <= this.totalPages; i++) {
          if (i >= this.currentPage - 2 && i <= this.currentPage) {
            pagesToShow.push(i)
          }
        }
      } else {
        for (let i = 1; i <= this.totalPages; i++) {
          if (i >= this.currentPage - 2 && i <= this.currentPage + 2) {
            pagesToShow.push(i)
          }
        }
      }
      return pagesToShow
    }
  },
  mounted() {
  },
  methods: {
    goToPage: function(page){
      if (page === this.currentPage) return
      this.$emit('changeCurrentPage', page)
    },
    goToPrev: function(){
      this.$emit('changeCurrentPage', this.currentPage - 1)
    },
    goToNext: function(){
      this.$emit('changeCurrentPage', this.currentPage + 1)
    },
  }
}
</script>
<style>
.pagination {
  display: flex;
  flex-direction: row;
}

.pagination li {
  text-decoration: none;
  list-style: none;
}

.pagination .btn-paginator {
  width: 20px!important;
  padding-left: 1rem;
  min-width: 0;
}

.pagination .btn-paginator.active {
  background-color: var(--purple-40);
}
</style>

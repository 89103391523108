import Axios from 'axios';
import moment from 'moment';

export const ProcedimientosServices = {
    api: {
        async getProcedimientos(filter) {
            return Axios.get('procedimientos', {params: filter})
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async getProcedimientosSinFirmar() {
            return Axios.get('procedimientos-sin-firmar')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async marcarComoLeido(procedimientoId) {
            return Axios.post('firmar-procedimiento/' + procedimientoId)
                .then((response) => response.data)
                .catch((error) => {
                    throw error
                })
        }
    },

    headers() {
        const header = [
            {value: 'Título', sortable: 'titulo'},
            {value: 'Desde', sortable: 'desde'},
            {value: 'Publicado', sortable: 'publicado'},
            {value: 'Requiere firma'},
        ]
        return header;
    },
    body() {
        const body = [
            {
                class: 'w-100 important text-bold',
                data: [{value: 'titulo'}]
            },
            {
                class: 'important',
                data: [{
                    value: 'desde', label: "Desde",
                    mutator: this.normalDate
                }]
            },
            {
                class: 'important',
                data: [{
                    value: 'publicado', label: "Publicado",
                    mutator: (item) => {
                        if (item) {
                            return 'Si';
                        }
                        return 'No';
                    }
                }]
            },
            {
                class: 'important',
                data: [{
                    value: 'requiere_firma', label: "Requiere firma",
                    mutator: (item) => {
                        if (item) {
                            return 'Si';
                        }
                        return 'No';
                    }
                }]
            }
        ]
        return body
    },
    normalDate(value) {
        let date = moment(value)
        return date.format('DD/MM/YYYY');
    },
}

export const UsuarioFirmadoService = {
    //Table list
    headers() {
        const header = [{value: 'Nombre', sortable: true}, {value: 'Notificado', sortable: true}]
        return header;
    },
    body() {
        const body = [
            {
                class: 'important',
                data: [{value: 'user.persona.nombre', class: "text-primary"}, {
                    value: 'user.persona.cuil',
                    class: 'text-small text-secondary',
                    mutator: (cuil) => {
                        return this.formatCuil(cuil)
                    }
                }]
            },
            {
                class: 'important',
                data: [{
                    value: 'firmado', mutator: (item) => {
                        return item ? 'SI' : 'NO'
                    }
                }]
            }
        ]
        return body
    },
    //MUTATORS
    formatCuil(value) {
        if (!value) return ''
        value = value.toString()
        if (value.length == 11) {
            let values = value.split("");
            values.splice(2, 0, "-");
            values.splice(11, 0, "-");
            return values.join("");
        }
        return value;
    }
}

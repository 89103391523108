import axios from "axios";

export const asignacionesFamiliaresApi = {
  getAll(filter) {
    return axios.get('asignaciones-familiares', {params: filter}).then(response => response.data);
  },
  show(id){
    return axios.get(`asignaciones-familiares/${id}`).then(response => response.data);
  },
  updateState(id, data){
    return axios.put(`asignaciones-familiares/${id}/estado`, data).then(response => response.data)
  }
}

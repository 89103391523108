<template>
  <div class="card">
    <h3 class="card-title">LISTAR LIQUIDACIONES</h3>
    <div >
      <DataTable
        ref="tablelist"
        :showFilter="true"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        :endpoint="LiquidacionesServices.api.getAll"
        @rowClicked="showLiquidacion"
      ></DataTable>
    </div>
  </div>
</template>
<script>
import TableList from "@/components/elements/TableList";
import {LiquidacionesServices} from '../services/LiquidacionesServices'
import DataTable from "@/components/elements/DataTable.vue";
export default {
  name:'ListarLiquidaciones',
  computed: {
    LiquidacionesServices() {
      return LiquidacionesServices
    }
  },
  components:{
    DataTable
  },
  data(){
    return{
      headers: LiquidacionesServices.headers(),
      body: LiquidacionesServices.body(),
      actionButton: {
        text: "Nueva liquidación",
        action: () => {
          this.$router.push({name:'NuevaLiquidacion'})
        },
      },

    }
  },
  methods:{
    showLiquidacion(liquidacion){
      this.$router.push({name: 'VerLiquidacion', params:{id : liquidacion.id}})
    }
  }
}
</script>

<template>
  <div class="card">
    <h3 class="card-title">Listar personas</h3>
    <DataTable
        :showFilter="true"
        :header="tableListHeader"
        :body="tableListBody"
        :actionButton="actionButton"
        :endpoint="PersonasService.api.getAll"
        @rowClicked="showPersona"
      />
  </div>
</template>
<script>
import { PersonasService } from "../services/PersonasServices";
import DataTable from "@/components/elements/DataTable.vue";
export default {
  name: "ListarPersonas",
  computed: {
    PersonasService() {
      return PersonasService
    }
  },
  components: {
    DataTable
  },
  data() {
    return {
      tableListBody: PersonasService.body(),
      tableListHeader: PersonasService.headers(),
      actionButton: {
        text: "Nueva persona",
        action: () => {
          this.$router.push({name: 'NuevaPersona'})
        },
      },
    };
  },
  methods: {
    showPersona(persona) {
      this.$router.push({name:'VerPersona', params:{id: persona.id}})
    },
  },
};
</script>

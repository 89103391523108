<template>
  <div>
    <div class="d-flex space-between" v-if="actionButton || actionButton2">
      <div class="mb-1 sm-w100">
        <button
            class="btn btn-primary"
            v-if="actionButton"
            @click="actionButton.action"
        >
          {{ actionButton.text }}
        </button>
        <button
            class="btn btn-primary"
            v-if="actionButton2"
            @click="actionButton2.action"
        >
          {{ actionButton2.text }}
        </button>
      </div>
      <div v-if="showFilter" class="mb-1 search">
        <pd-input
            id="searchInput"
            v-model="filter.search"
            placeholder="Buscar"
            label="Buscar"
            class=""
            @input="handleSearch"
        ></pd-input>
      </div>
    </div>
    <div v-else-if="showFilter" class="search third-right my-1">
      <pd-input
          id="searchInput"
          v-model="filter.search"
          placeholder="Buscar"
          label="Buscar"
          class=""
          @input="handleSearch"
      ></pd-input>
    </div>
    <table class="table-list">
      <thead>
      <tr>
        <th v-for="(th, i) in header" :key="i" @click="sort($event, th.sortable)" :class="[{'sortable': th.sortable}, th.class]">
          <span v-html="th.value"></span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(item, i) in filteredData"
          :key="i"
          @click="handleClick(item)"
      >
        <td v-for="(td, j) in body" :key="j" :class="td.class">
          <div class="list-item">
            <div class="list-body">
                <span v-for="(dato, k) in td.data" :key="k">
                  <span v-if="dato.label" class="list-label text-normal"
                  >{{ dato.label }}:</span
                  >
                  <span
                      :class="dato.class"
                      v-if="dato.mutator"
                      v-html="dato.mutator(showItem(item, dato.value))"
                  ></span>
                  <span v-else :class="dato.class">
                    {{ showItem(item, dato.value) }}
                  </span>
                </span>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="mt-4 d-flex w-full text-sm" style="justify-content: space-between">
      <div class="d-flex" style="align-items: center">
        <select
            v-model="filter.limit"
            class="rounded shadow-form custom-input"
            @change="getFirstPageData"
        >
          <option :value="10">10</option>
          <option :value="20">20</option>
          <option :value="50">50</option>
        </select>
        <span class="mx-1"> de </span>
        <span>{{ totalResults }}</span>
        <span class="mx-1"> Items </span>
      </div>
      <div>
        <BasePaginator
            :current-page="filter.page"
            :results-per-page="filter.limit"
            :total-results="totalResults"
            @changeCurrentPage="changePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BasePaginator from "@/components/elements/BasePaginator.vue";
import Swal from "sweetalert2";

export default {
  name: "TableList",
  components: {BasePaginator},
  props: {
    showFilter: {type: Boolean, required: true, default: false},
    header: {type: [], required: true, default: []},
    body: {type: [], required: true, default: []},
    data: {type: []},
    actionButton: null,
    actionButton2: null,
    endpoint: {type: Function, required: true},
    customFilters: {},
    disableRouterReplacement: false
  },
  data() {
    return {
      searchOptions: [],
      searchInput: null,
      searchIndex: null,
      searchOptionSelected: null,
      filteredData: [],
      filter: {
        search: '',
        page: 1,
        sortOrder: null,
        orderBy: null,
        limit: 10,
        paginated: true
      },
      totalResults: 0,
      timer: null
    };
  },
  mounted() {
    // this.filteredData = this.data;
    this.getData()
    let headers = document.querySelectorAll(
        ".table-list th[sortable], .table-list th[sortdown], .table-list th[sortup]"
    );
    this.mountSearchOptions(headers);
    headers.forEach((th) => {
      this.addListeners(th);
    });
  },
  methods: {
    mountSearchOptions(headers) {
      this.searchOptions.push({index: null, value: "Todos los datos"});
      headers.forEach((th) => {
        this.searchOptions.push({
          index: th.innerText.toLowerCase(),
          value: th.innerText,
        });
      });
    },
    addListeners(th) {
      th.addEventListener("click", (e) => {
        if (
            e.target.hasAttribute("sortable") ||
            e.target.hasAttribute("sortdown")
        ) {
          this.sortUp(e.target);
          return;
        }
        if (e.target.hasAttribute("sortup")) {
          this.sortDown(e.target);
          return;
        }
      });
    },
    async getData() {
      if (!this.endpoint) {
        return
      }

      try {
        let filterData = this.filter
        if (this.customFilters) {
          filterData = {...this.filter, ...this.customFilters}
        }
        const response = await this.endpoint(filterData)
        this.filteredData = response.data
        this.totalResults = response.total
        const queryFilterData = Object.assign({}, filterData)
        queryFilterData.paginated = queryFilterData.paginated ? "true" : "false"
        queryFilterData.limit = queryFilterData.limit ? queryFilterData.limit.toString() : null;
        queryFilterData.page = queryFilterData.page ? queryFilterData.page.toString() : null;
        if (JSON.stringify(queryFilterData) !== JSON.stringify(this.$route.query) && !this.disableRouterReplacement) {
          this.$router.replace({
            query: filterData
          })
        }
        this.$emit('onEndpointCalled')
      } catch (e) {
        Swal.fire("Error", `<p>${e}</p>`, "error");
      }
    },
    resetSortable() {
      let headers = document.querySelectorAll(".table-list th");
      headers.forEach((th) => {
        th.removeAttribute("sortup");
        th.removeAttribute("sortdown");
        if (!th.hasAttribute("sortable")) {
          th.setAttribute("sortable", "");
        }
      });
    },
    sortUp(element) {
      this.resetSortable();
      element.removeAttribute("sortable");
      element.setAttribute("sortup", "");
      this.sort(element, "ASC");
    },
    sortDown(element) {
      this.resetSortable();
      element.removeAttribute("sortable");
      element.setAttribute("sortdown", "");
      this.sort(element, "DESC");
    },
    // sort(element, order) {
    //   this.filteredData.sort((a, b) => {
    //     let data_a = "";
    //     let data_b = "";
    //     this.body[element.cellIndex].data.forEach((element) => {
    //       data_a += this.showItem(a, element.value);
    //       data_b += this.showItem(b, element.value);
    //     });
    //     if (!isNaN(data_a)) {
    //       data_a = parseFloat(data_a);
    //     }
    //     if (!isNaN(data_b)) {
    //       data_b = parseFloat(data_b);
    //     }
    //     if (data_a > data_b) {
    //       return order == "up" ? 1 : -1;
    //     }
    //     if (data_a < data_b) {
    //       return order == "up" ? -1 : 1;
    //     }
    //     return 0;
    //   });
    // },
    sort(e, name) {
      if (!name) {
        return
      }

      const currentHeader = e.target
      const tableHeaders = currentHeader.parentElement?.querySelectorAll('th')

      let sortOrder = ''

      tableHeaders?.forEach((tableHeader) => {
        if (
            tableHeader !== currentHeader &&
            (tableHeader.classList.contains('sortup') || tableHeader.classList.contains('sortdown'))
        ) {
          tableHeader.classList.remove('sortup')
          tableHeader.classList.remove('sortdown')
          tableHeader.classList.add('sortable')
        }
      })

      if (currentHeader.classList.contains('sortable') && sortOrder === '') {
        currentHeader.classList.remove('sortable')
        currentHeader.classList.add('sortup')
        sortOrder = 'ASC'
      }

      if (currentHeader.classList.contains('sortup') && sortOrder === '') {
        currentHeader.classList.remove('sortup')
        currentHeader.classList.add('sortdown')
        sortOrder = 'DESC'
      }

      if (currentHeader.classList.contains('sortdown') && sortOrder === '') {
        currentHeader.classList.remove('sortdown')
        currentHeader.classList.add('sortup')
        sortOrder = 'ASC'
      }
      this.filter.orderBy = name
      this.filter.sortOrder = sortOrder
      this.filter.page = 1

      this.getData()
    },
    showItem(item, value) {
      let keys = value.split(".");
      keys.forEach((k) => {
        if (!item) {
          return null;
        }
        item = item[k];
      });
      return item;
    },
    handleClick(item) {
      this.$emit("rowClicked", item);
    },
    refresh() {
      this.searchInput = null;
      this.filter.page = 1
      this.getData()
    },

    changePage(page) {
      this.filter.page = page
      this.getData()
    },

    getFirstPageData() {
      this.filter.page = 1
      this.getData()
    },
    handleSearch() {
      const hasNotSearched = !this.filter.search
      const haveEnteredAtLeast3Characters = this.filter.search && this.filter.search.length > 2
      if (hasNotSearched || haveEnteredAtLeast3Characters) {
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          this.filter.page = 1
          this.getData()
        }, 500)
      }
    }
  },
};
</script>

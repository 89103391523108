import Axios from 'axios';
import moment from 'moment';
import { required } from "vuelidate/lib/validators";

export const LicenciasServices = {
    api: {
        async getAll(filter) {
            return Axios.get('licencias', {params: filter})
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async save(licencias) {
            return Axios.post('licencias', licencias)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async find(id) {
            return Axios.get('licencias/' + id)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async put(id, licencia) {
            return Axios.put('licencias/' + id, licencia)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async delete(licencia) {
            return Axios.delete('licencias/' + licencia.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async buscarPorEmpleado(empleado, tipo, periodo) {
            let query = {
                empleado: (empleado) ? empleado.id : null,
                tipo_licencia_id: (tipo) ? tipo.id : null,
                periodo: periodo,

            }
            return Axios.get('licencias/por-empleado/', { params: query })
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async buscarPorTipo(tipo, periodo) {
            let query = {
                tipo_licencia_id: (tipo) ? tipo.id : null,
                periodo: periodo,

            }
            return Axios.get('licencias/por-tipo/', { params: query })
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async buscarPorGerencia(gerencia, tipo, periodo) {
            let query = {
                gerencia: (gerencia) ? gerencia.id : null,
                tipo_licencia_id: (tipo) ? tipo.id : null,
                periodo: periodo,

            }
            return Axios.get('licencias/por-gerencia/', { params: query })
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async buscarPorArea(area, tipo, periodo) {
            let query = {
                area: (area) ? area.id : null,
                tipo_licencia_id: (tipo) ? tipo.id : null,
                periodo: periodo,

            }
            return Axios.get('licencias/por-area/', { params: query })
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        }
    },
    //DATA TABLES
    headers() {
        const header = [
            { value: "Persona", sortable: "personas.nombre" },
            { value: "Licencia", sortable: "tipo_licencia_descripcion" },
            { value: "Período", sortable: 'periodo' },
            { value: "Desde", sortable: 'desde' },
            { value: "Hasta", sortable: 'hasta' },
            { value: "Días", sortable: 'cantidad_dias' },
            { value: "Gozadas", sortable: 'gozados' },
            { value: "Corresponden", sortable: 'corresponden' },
            { value: "Pendientes", sortable: 'pendientes' },
        ]
        return header;
    },
    body() {
        const body = [{
                class: "w-100 important",
                data: [
                    { value: "persona.nombre", class: "text-primary" },
                    {
                        value: "persona.cuil",
                        class: "text-small text-secondary",
                        mutator: this.formatCuil,
                    },
                ],
            },
            {
                class: "w-100 important",
                data: [
                    { value: "tipo_licencia_descripcion", class: "text-bold" },
                    { value: "tipo_licencia_convenio", class: "text-secondary text-small" },
                ],
            },
            {
                class: "w-100",
                data: [{ value: "periodo", label: "Período" }],
            },
            {
                class: "w-100",
                data: [{
                    value: "desde",
                    label: "Desde",
                    mutator: this.normalDate,
                }, ],
            },
            {
                class: "w-100",
                data: [{
                    value: "hasta",
                    label: "Hasta",
                    mutator: this.normalDate,
                }, ],
            },
            {
                class: "w-100",
                data: [{ value: "cantidad_dias", label: "Días" }],
            },
            {
                class: "w-100",
                data: [{ value: "gozados", label: "Gozadas" }],
            },
            {
                class: "w-100",
                data: [{
                    value: "corresponden",
                    label: "Corresponden",
                    mutator: (value) => {
                        if (value === null) {
                            return 'Sin tope'
                        } else {
                            return value
                        }
                    }
                }],
            },
            {
                class: "w-100",
                data: [{
                    value: "pendientes",
                    label: "Pendientes",
                    mutator: (value) => {
                        if (value === null) {
                            return 'Sin tope'
                        } else {
                            return value
                        }
                    }
                }],
            },
        ]
        return body
    },
    //MUTATORS
    formatCuil(value) {
        if (!value) return ''
        value = value.toString()
        if (value.length == 11) {
            let values = value.split("");
            values.splice(2, 0, "-");
            values.splice(11, 0, "-");
            return values.join("");
        }
        return value;
    },
    normalDate(value) {
        if(!value){
            return '-'
        }
        let date = moment(value)
        return date.format('DD/MM/YYYY');
    },
    //VALIDATIONS
    validations: {
        fecha_solicitud: { required },
        cantidad_dias: { required },
        periodo: { required },
        desde: { required },
        hasta: { required },
        fecha_reincorporacion: { required },
    }
}

<template>
  <div class="card">
    <h2 class="card-title">Listado de noticias</h2>
    <DataTable
        :show-filter="true"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        :endpoint="NoticiasServices.api.getAll"
        @rowClicked="verNoticia"
    />
  </div>
</template>
<script>
import TableList from "@/components/elements/TableList";
import { NoticiasServices } from "../services/NoticiasServices";
import DataTable from "@/components/elements/DataTable.vue";

export default {
  name: "ListarNoticias",
  computed: {
    NoticiasServices() {
      return NoticiasServices
    }
  },
  components: { TableList, DataTable },
  data() {
    return {
      headers: NoticiasServices.headers(),
      body: NoticiasServices.body(),
      actionButton: {
        text: "Nueva noticia",
        action: () => {
          this.$router.push({name: 'CrearNoticia'})
        },
      },
    };
  },
  methods:{
    verNoticia(noticia){
      this.$router.push({ name: 'VerNoticia', params:{ id: noticia.id } })
    }
  }
};
</script>
